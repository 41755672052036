
.pdp-river-content {
    display flex
    flex-direction column
    margin 68px auto 0
    width 100%
    max-width 1018px
    padding 32px 0
    font-family "SF Pro Text", -apple-system, "system-ui"
    font-size 16px
    font-style normal
    font-weight 400
    letter-spacing 0.6px
    line-height 28.8px
    text-align center
    color rgba(18, 18, 18, 0.75)

    border: 1px solid #d0d0d0;
    border-radius: 8px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 16px;

    margin 40px auto

    .pdp-river-content-title {
        text-align: center;
        color: #000;
        font-size: 32px;
        line-height: 34px;
        font-weight: 600 !important;
        letter-spacing: -.02em !important;
        font-family: SF Pro Text;
    }

    .pdp-river-content-action-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 40px;

        .pdp-river-content-action{
            align-items center
            box-sizing border-box
            color rgb(0, 113, 227)
            cursor pointer
            display flex
            flex-direction row
            font-family "SF Pro Text"
            font-size 14px
            font-style normal
            font-weight 600
            height 44px
            justify-content center
            letter-spacing -0.2px
            line-height 20px
            margin-bottom 0px
            margin-left 0px
            margin-right 0px
            margin-top 0px
            padding-bottom 12px
            padding-left 0px
            padding-right 0px
            padding-top 12px
            text-align center

            .v-icon {
                color rgb(0, 113, 227)
            }
        }
    }
}
